<template>
  <div class="card">
    <div class="card-header">
      <h3 class="cart-title">
        <a data-action="collapse">Main ERC panel</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div
      v-if="user.main_ercs"
      class="card-content collapse show"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-12">
                <div
                  :class="{'required': !isAdmin}"
                  class="mb-1 form-group"
                >
                  <label
                    for=""
                    class="form-label"
                  >Level 1</label>
                  <v-select
                    v-model="user.main_ercs.level1"
                    label="custom_name"
                    :disabled="!edit"
                    :options="levels1"
                    :get-option-key="option => option.id"
                    @input="getChildrens($event, 'level1')"
                  />
                </div>
              </div>
              <div class="col-12">
                <div
                  :class="{'required': !isAdmin}"
                  class="mb-1 form-group"
                >
                  <label
                    for=""
                    class="form-label"
                  >Level 2</label>
                  <v-select
                    v-model="user.main_ercs.level2"
                    label="custom_name"
                    :disabled="!edit"
                    :options="levels2"
                    :get-option-key="option => option.id"
                    @input="getChildrens($event, 'level2')"
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="mb-1 form-group">
                  <label
                    for=""
                    class="form-label"
                  >Level 3</label>
                  <v-select
                    v-model="user.main_ercs.level3"
                    label="custom_name"
                    :disabled="!edit"
                    :options="levels3"
                    :get-option-key="option => option.id"
                    @input="getChildrens($event, 'level3')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-2">
        <a
          class="btn btn-sm btn-dark ms-2"
          :class="{'d-none': edit}"
          @click="edit = !edit"
        >Edit panel</a>
        <a
          class="btn btn-sm btn-success ms-2"
          :class="{'d-none': !edit}"
          @click="edit = !edit"
        >Save</a>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'

export default {
  components: {
    vSelect,
  },
  props: {
    user: { type: Object, required: true, default: () => {} },
    selectedRoles: { type: Array, required: false, default: () => [] },
  },
  data() {
    return {
      sending: false,
      errors: '',
      levels2: [],
      levels3: [],
      edit: false,
      // level1: null,
      // level2: null,
      // level3: null,
    }
  },
  computed: {
    ...mapGetters({
      levels1: 'erc/parentLevels',
      loggedUser: 'auth/admin',
    }),
    additionalErcs() {
      return this.user.additional_ercs
    },
    isAdmin() {
      return this.loggedUser.roles.includes('super-admin')
    },
  },
  watch: {
    additionalErcs() {
      this.setCorrectValues()
    },
  },
  async mounted() {
    await this.$store.dispatch('erc/getParents')

    if (this.user.main_ercs && this.user.main_ercs.level1 && Object.keys(this.user.main_ercs.level1).length > 0) {
      this.loadChildrens(this.user.main_ercs.level1, 'levels2')
    }

    if (this.user.main_ercs && this.user.main_ercs.level2 && Object.keys(this.user.main_ercs.level2).length > 0) {
      this.loadChildrens(this.user.main_ercs.level2, 'levels3')
    }

    this.setCorrectValues()
  },
  methods: {
    setCorrectValues() {
      if (this.user.main_ercs && this.user.main_ercs.level1 && Object.keys(this.user.main_ercs.level1).length > 0) {
        this.user.main_ercs.level1 = { ...this.user.main_ercs.level1, num_level: 1 }
        this.levels2 = this.user.main_ercs.level1.childrens
      }
      if (this.user.main_ercs && this.user.main_ercs.level2 && Object.keys(this.user.main_ercs.level2).length > 0) {
        this.user.main_ercs.level2 = { ...this.user.main_ercs.level2, num_level: 2 }
        this.levels3 = this.user.main_ercs.level2.childrens
      }

      if (this.user.main_ercs && this.user.main_ercs.level3 && Object.keys(this.user.main_ercs.level3).length > 0) {
        this.user.main_ercs.level3 = { ...this.user.main_ercs.level3, num_level: 3 }
      }
    },
    loadChildrens($event, $parent) {
      Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/ercs/${$event.id}/childrens`).then(resp => {
        this[$parent] = resp.data
      })
    },
    getChildrens($event, $parent) {
      switch ($parent) {
        case 'level1':
          this.user.main_ercs.level1 = $event ? { ...$event, num_level: 1 } : null
          if (this.user.main_ercs.level1) {
            Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/ercs/${this.user.main_ercs.level1.id}/childrens`).then(resp => {
              this.user.main_ercs.level2 = null
              this.user.main_ercs.level3 = null
              this.levels2 = resp.data
              this.levels3 = []
            })
          }
          break
        case 'level2':
          this.user.main_ercs.level2 = $event ? { ...$event, num_level: 2 } : null
          if (this.user.main_ercs.level2) {
            Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/ercs/${this.user.main_ercs.level2.id}/childrens`).then(resp => {
              this.user.main_ercs.level3 = null
              this.levels3 = resp.data
            })
          }
          break
        case 'level3':
          this.user.main_ercs.level3 = $event ? { ...$event, num_level: 3 } : null
          break
        default:
      }
    },
  },

}
</script>
